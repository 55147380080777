import React from "react";
import styles from "./AuctionComponents.module.scss";

const ForwardArrow = ({ onClick }) => (
  <button
    aria-label="Forward"
    className={styles.forwardArrow}
    onClick={onClick}
    type="button"
  />
);

export default ForwardArrow;
