import React from "react";
import cn from "classnames";
import styles from "./Button.module.scss";
import LoadingSmall from "../Loading/LoadingSmall";

const DefaultButton = ({
  outlined = false,
  title,
  onClick,
  disabled = false,
  loading = false,
  extraClasses = null,
}) => (
  <button
    disabled={disabled}
    type="button"
    className={cn(
      styles.defaultButton,
      {
        [styles.outlined]: outlined,
      },
      extraClasses
    )}
    onClick={onClick}
  >
    {!loading && title}
    {loading && <LoadingSmall />}
  </button>
);

export default DefaultButton;
