import React, { useEffect } from "react";
import Imgix from "react-imgix";
import { Link, navigate } from "gatsby";
import cn from "classnames";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import { useSelector } from "react-redux";
import styles from "./AuctionCreation.module.scss";
import baseStyles from "./Base.module.scss";
import DefaultButton from "../../../../Buttons/DefaultButton";
import { useAppDispatch } from "../../../../../store";
import { selectAvailableMarkets } from "../../../../../store/user/selectors";
import { Market } from "../../../../../types/Market";
import {
  getUserInfo,
  getUserInstagramAccounts,
} from "../../../../../store/user/actions";
import { PathTypes } from "./Types/PathTypes";
import { getAuction } from "../../../../../store/auctionModal/selectors";
import { Auction } from "../../../../../types/Auction";
import { updateAuction } from "../../../../../store/auctionModal/reducer";
import { sendToast } from "../../../../../utils/helpers";
import { MethodTypes } from "../../../../../enums/MethodTypes";
import { AccountTypes } from "../../../../../enums/AccountTypes";

const CreateAuctionBidddy =
  "https://imgix.cosmicjs.com/a8af4fd0-c3f8-11ec-bf80-e74645a81647-CreateAuction.png";
const CreateAuctionBidddyDesktop =
  "https://imgix.cosmicjs.com/bd4b8320-c8d3-11ec-bea6-99436435ce05-CreateAuctionDesktop.png";

export const AuctionType = {
  QUICKSTART: "QUICKSTART",
  QUICKSTARTPLUS: "QUICKSTARTPLUS",
  PRESCHEDULE: "PRESCHEDULE",
};

const NewCreateAuction = ({ location }) => {
  const dispatch = useAppDispatch();
  const availableMarkets = useSelector((state) =>
    selectAvailableMarkets(state)
  ) as Market[];
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const error =
    location.state && location.state.error ? location.state.error : null;

  useEffect(() => {
    if (error) {
      sendToast(error, { type: "error" });
    }
  }, [error]);
  useEffect(() => {
    // Check accounts
    dispatch(getUserInfo());
    dispatch(getUserInstagramAccounts());
  }, []);

  const saveLocalAuction = (auct: Auction) => {
    // Saves a local auction without saving to server
    dispatch(updateAuction(auct));
  };

  const handleAuctionType = (type: any) => {
    if (availableMarkets && availableMarkets.length === 1) {
      // TODO: update current auction
      const auct = JSON.parse(JSON.stringify(auction)) as Auction;

      const accountType = availableMarkets[0].account_type;
      switch (type) {
        case AuctionType.QUICKSTART:
          // Navigate to quickstart
          if (
            accountType === AccountTypes.BUSINESS ||
            accountType === AccountTypes.MEDIA_CREATOR
          ) {
            auct.market_id = availableMarkets[0].id;
            saveLocalAuction(auct);
            navigate("/dashboard/auctions/quickstart/", {
              state: {
                ...location.state,
                fromPath: PathTypes.AUCTION_CREATION_MAIN,
                selectedAuctionType: type,
              },
            });
          } else {
            saveLocalAuction(auct);
            navigate("/dashboard/auctions/create/choose-account/", {
              state: {
                ...location.state,
                fromPath: PathTypes.AUCTION_CREATION_MAIN,
                selectedAuctionType: type,
              },
            });
          }
          return;
        case AuctionType.QUICKSTARTPLUS:
          // Navigate to Quickstart plus
          auct.type = MethodTypes.code;

          if (
            accountType === AccountTypes.BUSINESS ||
            accountType === AccountTypes.MEDIA_CREATOR
          ) {
            auct.market_id = availableMarkets[0].id;
            saveLocalAuction(auct);
            navigate("/dashboard/auctions/create/add-title/", {
              state: {
                ...location.state,
                fromPath: PathTypes.AUCTION_CREATION_MAIN,
                selectedAuctionType: type,
              },
            });
          } else {
            saveLocalAuction(auct);
            navigate("/dashboard/auctions/create/choose-account/", {
              state: {
                ...location.state,
                fromPath: PathTypes.AUCTION_CREATION_MAIN,
                selectedAuctionType: type,
              },
            });
          }
          return;
        default:
          // Navigate to Preschedule
          auct.type = MethodTypes.schedule;

          if (accountType === AccountTypes.BUSINESS) {
            auct.market_id = availableMarkets[0].id;
            saveLocalAuction(auct);
            navigate("/dashboard/auctions/create/add-title/", {
              state: {
                ...location.state,
                fromPath: PathTypes.AUCTION_CREATION_MAIN,
                selectedAuctionType: type,
              },
            });
          } else {
            saveLocalAuction(auct);
            navigate("/dashboard/auctions/create/choose-account/", {
              state: {
                ...location.state,
                fromPath: PathTypes.AUCTION_CREATION_MAIN,
                selectedAuctionType: type,
              },
            });
          }
      }
    } else if (availableMarkets.length > 1) {
      // Navigate to select market page
      const auct = JSON.parse(JSON.stringify(auction)) as Auction;

      const accountType = availableMarkets[0].account_type;
      switch (type) {
        case AuctionType.QUICKSTART:
          // Navigate to quickstart
          if (
            accountType === AccountTypes.BUSINESS ||
            accountType === AccountTypes.MEDIA_CREATOR
          ) {
            auct.market_id = availableMarkets[0].id;
          } else {
            auct.market_id = null;
          }
          saveLocalAuction(auct);
          navigate("/dashboard/auctions/create/choose-account/", {
            state: {
              ...location.state,
              fromPath: PathTypes.AUCTION_CREATION_MAIN,
              selectedAuctionType: type,
            },
          });
          return;
        case AuctionType.QUICKSTARTPLUS:
          // Navigate to Quickstart plus
          auct.type = MethodTypes.code;
          if (
            accountType === AccountTypes.BUSINESS ||
            accountType === AccountTypes.MEDIA_CREATOR
          ) {
            auct.market_id = availableMarkets[0].id;
          } else {
            auct.market_id = null;
          }
          saveLocalAuction(auct);
          navigate("/dashboard/auctions/create/choose-account/", {
            state: {
              ...location.state,
              fromPath: PathTypes.AUCTION_CREATION_MAIN,
              selectedAuctionType: type,
            },
          });
          return;
        default:
          // Navigate to Preschedule
          auct.type = MethodTypes.schedule;
          if (availableMarkets[0].account_type === AccountTypes.BUSINESS) {
            auct.market_id = availableMarkets[0].id;
          } else {
            auct.market_id = null;
          }
          saveLocalAuction(auct);
          navigate("/dashboard/auctions/create/choose-account/", {
            state: {
              ...location.state,
              fromPath: PathTypes.AUCTION_CREATION_MAIN,
              selectedAuctionType: type,
            },
          });
      }
    } else {
      sendToast(
        "There is no available Instagram accounts connected. Please go to your account page and connect your Instagram account.",
        { type: "warning" }
      );
    }
  };

  return (
    <div className={baseStyles.baseAuctionContainer}>
      <div className={baseStyles.topContainer}>
        <div className={baseStyles.imageContainer}>
          <div className={baseStyles.mobileOnly}>
            <Imgix
              src={`${CreateAuctionBidddy}?auto=format`}
              height={222}
              width={160}
              htmlAttributes={{
                alt: "Select Auction Type",
                src: `${CreateAuctionBidddy}?blur=100&px=2&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          </div>
          <div className={baseStyles.desktopOnly}>
            <Imgix
              src={`${CreateAuctionBidddyDesktop}?auto=format`}
              height={570}
              width={570}
              htmlAttributes={{
                alt: "Select Auction Type",
                src: `${CreateAuctionBidddyDesktop}?blur=100&px=2&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          </div>
        </div>
        <div className={baseStyles.textContainer}>
          <div>
            <h3>Woo Hoo!</h3>
            <h1>
              Let&apos;s Create an
              <br /> Auction.
            </h1>
            <p>What type of Instagram auction would you like to create?</p>
            <div className={cn(styles.buttonListContainer)}>
              <DefaultButton
                onClick={() => handleAuctionType(AuctionType.QUICKSTART)}
                title="QUICK START"
              />
              <DefaultButton
                onClick={() => handleAuctionType(AuctionType.QUICKSTARTPLUS)}
                title="QUICK START PLUS"
              />
              <DefaultButton
                onClick={() => handleAuctionType(AuctionType.PRESCHEDULE)}
                title="SCHEDULE"
              />
            </div>
          </div>
          <div className={styles.separatedContainer}>
            <div className={baseStyles.bottomContainer}>
              <Link to="/learn/how-to-create-an-auction-on-instagram/">
                Need Help Choosing?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCreateAuction;
