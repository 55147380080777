/* eslint-disable no-sparse-arrays */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import { navigate } from "gatsby";
import styles from "./AuctionSteps.module.scss";
import { useAppDispatch } from "../../../../../../store";
import {
  getAuction,
  getAuctionModalIsEditing,
} from "../../../../../../store/auctionModal/selectors";
import { updateAuction } from "../../../../../../store/auctionModal/reducer";
import BasicTable from "../../../../../Table/BasicTable";
import BasicTableHeader from "../../../../../Table/BasicTableHeader";
import BasicTableRow from "../../../../../Table/BasicTableRow";
import BasicTableBody from "../../../../../Table/BasicTableBody";
import ForwardArrow from "../internal/ForwardArrow";
import {
  getUserInfo,
  getUserInstagramAccounts,
} from "../../../../../../store/user/actions";
import {
  selectAvailableMarkets,
  selectRequestingUserInfo,
  selectUserInfo,
  selectUserInfoLoading,
} from "../../../../../../store/user/selectors";
import { baseURL } from "../../../../../../utils/axios";
import { Market } from "../../../../../../types/Market";
import { Auction } from "../../../../../../types/Auction";
import Axios from "../../../../../../extensions/Axios";
import { event } from "../../../../../../lib/ga";
import { sendToast } from "../../../../../../utils/helpers";
import AuctionButtonsContainer from "../internal/AuctionButtonsContainer";
import { AccountTypes } from "../../../../../../enums/AccountTypes";
import Tooltip from "../../../../../Tooltip/Tooltip";
import { AuctionType } from "../../NewAuctionCreation/NewCreateAuction";

const InfoIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 17C3.80558 17 0 13.1944 0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5C16.9948 13.1923 13.1923 16.9948 8.5 17ZM1.7 8.6462C1.74022 12.3873 4.79494 15.3931 8.53616 15.3731C12.2774 15.3529 15.2996 12.3144 15.2996 8.5731C15.2996 4.83183 12.2774 1.79331 8.53616 1.7731C4.79494 1.75309 1.74022 4.75895 1.7 8.5V8.6462ZM10.2 12.75H7.65V9.35H6.8V7.65H9.35V11.05H10.2V12.75ZM9.35 5.95H7.65V4.25H9.35V5.95Z"
      fill="#ABABAB"
    />
  </svg>
);

const InstagramIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.3166 21.7255C27.3166 24.8006 24.8528 27.3166 21.8417 27.3166H8.16084C5.15028 27.3166 2.68652 24.8006 2.68652 21.7255V8.27766C2.68652 5.20247 5.15028 2.68652 8.16084 2.68652H21.8417C24.8528 2.68652 27.3166 5.20247 27.3166 8.27766V21.7255ZM22.0924 0H7.90762C3.558 0 0 3.6338 0 8.07601V21.924C0 26.3662 3.558 30 7.90762 30H22.0924C26.442 30 30 26.3662 30 21.924V8.07601C30 3.6338 26.442 0 22.0924 0Z"
      fill="#606060"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9968 19.9714C12.3094 19.9714 10.1309 17.7462 10.1309 15.0016C10.1309 12.2564 12.3094 10.0319 14.9968 10.0312C17.6849 10.0312 19.8635 12.2564 19.8635 15.0016C19.8635 17.7462 17.6849 19.9714 14.9968 19.9714ZM14.9973 7.29785C10.8316 7.29785 7.45508 10.7465 7.45508 15.001C7.45508 19.2556 10.8316 22.7042 14.9973 22.7042C19.1637 22.7042 22.5402 19.2556 22.5402 15.001C22.5402 10.7465 19.163 7.29785 14.9973 7.29785Z"
      fill="#606060"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6838 7.05746C23.6838 7.92958 22.977 8.63641 22.1048 8.63641C21.2327 8.63641 20.5259 7.92958 20.5259 7.05746C20.5259 6.18535 21.2327 5.47852 22.1048 5.47852C22.977 5.47852 23.6838 6.18535 23.6838 7.05746Z"
      fill="#606060"
    />
  </svg>
);

const AddAccountIcon = () => (
  <svg
    className="mb-1"
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1914 9.53835V4.73722H14.1467V9.53835H13.1914ZM11.2702 7.61364V6.65838H16.0714V7.61364H11.2702Z"
      fill="#505659"
    />
    <path
      d="M11.3392 18V16.7076C11.3392 16.0221 11.0669 15.3646 10.5821 14.8799C10.0974 14.3951 9.43993 14.1228 8.7544 14.1228H3.5848C2.89927 14.1228 2.24181 14.3951 1.75707 14.8799C1.27233 15.3646 1 16.0221 1 16.7076V18"
      stroke="#505659"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.16878 11.5378C7.59633 11.5378 8.75359 10.3805 8.75359 8.95296C8.75359 7.52542 7.59633 6.36816 6.16878 6.36816C4.74124 6.36816 3.58398 7.52542 3.58398 8.95296C3.58398 10.3805 4.74124 11.5378 6.16878 11.5378Z"
      stroke="#505659"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BidddyImage =
  "https://imgix.cosmicjs.com/3d375d20-c983-11ec-bea6-99436435ce05-BidfootBackground.png";
const BidddySkateboard =
  "https://imgix.cosmicjs.com/516e1560-d494-11ec-8319-8131fa54d98a-BidddyHoldingSkateboard.png";
const ChooseAnAccount = ({ location }) => {
  const dispatch = useAppDispatch();

  const auction = useSelector((state) => getAuction(state)) as Auction;
  const userInfo = useSelector((state) => selectUserInfo(state));
  const availableMarkets = useSelector((state) =>
    selectAvailableMarkets(state)
  ) as Market[];
  const loading = useSelector((state) =>
    selectUserInfoLoading(state)
  ) as boolean;
  const requestingUserInfo = useSelector((state) =>
    selectRequestingUserInfo(state)
  );
  const isEditing = useSelector((state) => getAuctionModalIsEditing(state));
  const [isLoading, setIsLoading] = useState(false);
  const [linkUrl, setLinkUrl] = useState(null);
  const [selectedId, setSelectedId] = useState(
    auction && auction.market_id ? auction.market_id : null
  );

  const selectedAuctionType =
    location && location.state && location.state.selectedAuctionType
      ? location.state.selectedAuctionType
      : null;
  const originationPath =
    location && location.state && location.state.originationPath
      ? location.state.originationPath
      : null;

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getUserInstagramAccounts());
    (async () => {
      setIsLoading(true);
      let url = "/dashboard/auctions/create/choose-account/";
      if (isEditing) {
        url = `/dashboard/auctions/${auction.id}/choose-account/`;
      }
      const currentStateObject = {
        type: "CONNECT_INSTAGRAM",
        selectedAuctionType: selectedAuctionType || "",
        fromPath: "CHOOSE_ACCOUNT",
        toPath: url,
        originationPath: originationPath || url,
      };
      // Handle stuff.
      try {
        const response = await Axios.get(
          `${baseURL}/link/instagram?format=json&state=${encodeURIComponent(
            JSON.stringify(currentStateObject)
          )}`
        );
        setLinkUrl(response.data.url);
        setIsLoading(false);
      } catch (err) {
        sendToast("There was an error requesting instagram permissions.", {
          type: "error",
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (!userInfo && !requestingUserInfo) {
      dispatch(getUserInfo());
    }
  }, [availableMarkets]);

  const signInToInsta = () => {
    if (typeof window !== "undefined" && linkUrl) {
      event({
        action: "instagram_auth_attempt",
        params: {},
      });
      setIsLoading(true);
      window.location.href = linkUrl;
    }
  };

  const updateAuctionWithMarketId = () => {
    if (isLoading) {
      return;
    }
    if (!selectedId) {
      sendToast(
        "Please select the account you want to create the auction on.",
        { type: "warning" }
      );
      return;
    }
    const auct = JSON.parse(JSON.stringify(auction)) as Auction;
    auct.market_id = selectedId;

    // Schedule and post here if multi type
    setIsLoading(true);
    Object.preventExtensions(auct);
    dispatch(updateAuction(auct));

    if (selectedAuctionType === AuctionType.QUICKSTART) {
      navigate("/dashboard/auctions/quickstart/", {
        state: { ...location?.state },
      });
      return;
    }

    navigate("/dashboard/auctions/create/add-title/", {
      state: { ...(location?.state || {}) },
    });
  };

  const checkMarketLinked = (market: Market) =>
    // Get Market name
    market.facebook_connected;

  const checkValidAccountType = (market: Market) => {

    // if (
    //   selectedAuctionType === AuctionType.PRESCHEDULE &&
    //   (market.account_type !== AccountTypes.BUSINESS || market.account_type !== AccountTypes.UNKNOWN)
    // ) {
    //   return false;
    // }
    return true;
  };

  const getAccountType = (market: Market) => {
    if (!checkMarketLinked(market)) {
      return "Account Unavailable";
    }
    switch (market.account_type) {
      case AccountTypes.BUSINESS:
        return "Business Account";
      case AccountTypes.MEDIA_CREATOR:
        return "Creator Account";
      case AccountTypes.UNKNOWN:
        return "Account Available";
      default:
        return "Account Unavailable";
    }
  };

  const getMarketButtonText = (market: Market) => {
    if (!checkValidAccountType(market) && checkMarketLinked(market)) {
      return "";
    }
    if (checkMarketLinked(market) && checkValidAccountType(market)) {
      return "";
    }
    return "";
  };

  const connectInstaAccount = (market: Market) => {
    if (checkMarketLinked(market) && checkValidAccountType(market)) {
      setSelectedId(market.id);
    }
  };

  const handleVerifyButtonClick = (market: Market) => {
    if (!checkMarketLinked(market)) {
      // go to connect page
      setIsLoading(true);
      window.location.href = linkUrl;
      return;
    }
    if (!checkValidAccountType(market)) {
      // Not correct account type for auction go to switch page
      navigate("/learn/switch-to-instagram-business-creator-account/");
    }
  };

  const getTitle = () => {
    switch (selectedAuctionType) {
      case AuctionType.PRESCHEDULE:
        return (
          <h2>
            Select an Instagram
            <br /> Business Account
          </h2>
        );
      default:
        return (
          <h2>
            Select Instagram
            <br /> Account
          </h2>
        );
    }
  };

  const isPrescheduleBusiness = (market) =>
    selectedAuctionType === AuctionType.PRESCHEDULE &&
    market.account_type !== AccountTypes.BUSINESS;

  return (
    <div className={cn([styles.creationContainer])}>
      <div className={cn([styles.creationImageContainer, styles.desktopOnly])}>
        <Imgix
          src={`${BidddyImage}?auto=format`}
          height={570}
          width={570}
          htmlAttributes={{
            alt: "Enter a title",
            src: `${BidddyImage}?blur=100&px=2&auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
          className="lazyload blur-up"
        />
      </div>
      <div className={cn([styles.creationImageContainer, styles.mobileOnly])}>
        <Imgix
          src={`${BidddySkateboard}?auto=format`}
          height={181}
          width={140}
          htmlAttributes={{
            alt: "Enter a title",
            src: `${BidddySkateboard}?blur=100&px=2&auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
          className="lazyload blur-up"
        />
      </div>
      <div className={styles.textContainer}>
        <h3>Ready.</h3>
        {getTitle()}
        <BasicTable>
          <BasicTableHeader>
            <BasicTableRow>
              <td colSpan={1}>Accounts</td>
              <td className={styles.accountCheckContainer}>
                <div
                  tabIndex={0}
                  className="flex flex-row items-center text-black justify-end"
                  role="button"
                  onKeyDown={() => signInToInsta()}
                  onClick={() => signInToInsta()}
                >
                  <AddAccountIcon />
                  Add Account
                </div>
              </td>
            </BasicTableRow>
          </BasicTableHeader>
          <BasicTableBody>
            {availableMarkets.length === 0 && (
              <BasicTableRow onClick={() => signInToInsta()}>
                <td className="flex flex-row">
                  <InstagramIcon /> <p className="ml-2 mt-1">Instagram</p>
                </td>
                <td className="text-right pb-2">
                  Sign In <ForwardArrow onClick={() => signInToInsta()} />
                </td>
              </BasicTableRow>
            )}
            {availableMarkets.length > 0 &&
              availableMarkets.map((market) => (
                <BasicTableRow
                  key={`account-${market.name}`}
                  onClick={() => connectInstaAccount(market)}
                >
                  <td
                    className={cn([
                      styles.defaultAccount,
                      {
                        [styles.activeAccount]:
                          checkValidAccountType(market) &&
                          checkMarketLinked(market),
                        [styles.notActiveAccount]:
                          !checkValidAccountType(market) ||
                          !checkMarketLinked(market),
                      },
                      ,
                      { [styles.selectedAccount]: selectedId === market.id },
                    ])}
                  >
                    <InstagramIcon />
                    <div className="flex flex-col justify-start items-start">
                      <p className={styles.marketName}>@{market.name}</p>
                      <div className="ml-4 flex flex-row">
                        <p
                          className={cn([
                            styles.marketType,
                            {
                              [styles.validAccount]:
                                checkValidAccountType(market) &&
                                checkMarketLinked(market),
                              [styles.notValidAccount]:
                                !checkValidAccountType(market) ||
                                !checkMarketLinked(market),
                            },
                          ])}
                        >
                          {getAccountType(market)}
                        </p>
                        {(!checkValidAccountType(market) ||
                          !checkMarketLinked(market)) && (
                          <button
                            type="button"
                            onClick={() => handleVerifyButtonClick(market)}
                            className={cn([styles.verifyButton])}
                          >
                            {getMarketButtonText(market)}
                          </button>
                        )}
                      </div>
                      {isPrescheduleBusiness(market) && (
                        <p
                          className={cn([
                            styles.marketType,
                            styles.notValidAccount,
                            "ml-4",
                          ])}
                        >
                          {" "}
                          <a
                            className={cn([styles.verifyButton, "ml-0"])}
                            href="/learn/how-to-link-facebook-page/"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                          >
                            Switch to Business Account.
                          </a>
                        </p>
                      )}
                    </div>
                  </td>
                  {checkValidAccountType(market) && checkMarketLinked(market) && (
                    <td className="text-right pb-2 relative">
                      {selectedId === market.id && (
                        <div className={styles.checkmarkYellow} />
                      )}
                      {selectedId !== market.id && (
                        <div className={styles.emptyCircle} />
                      )}
                    </td>
                  )}
                  {(!checkValidAccountType(market) ||
                    !checkMarketLinked(market)) && (
                    <td className="relative">
                      <Tooltip
                        content={
                          <p>
                            Scheduling auctions REQUIRES a Business account.
                          </p>
                        }
                      >
                        <InfoIcon />
                      </Tooltip>
                    </td>
                  )}
                </BasicTableRow>
              ))}
          </BasicTableBody>
        </BasicTable>
        <div className={styles.accountCheckContainer}>
          <p>
            Don&apos;t see your account or account unavailable? You will need an
            Instagram Creator or Business Account and a Facebook Page linked to
            your account.
            <br />{" "}
            <a
              className={styles.clickHere}
              href="/learn/Instagram-account-unavailable-or-not-appearing/"
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              Learn More.
            </a>
          </p>
        </div>
        <div className={styles.accountCheckContainer}>
          <p>
            Already have a Creator or Business Account, but account still
            unavailable?{" "}
            <button
              type="button"
              className={styles.clickHere}
              onClick={() => signInToInsta()}
            >
              Reconnect Now
            </button>
          </p>
        </div>
        <div className={cn([styles.learnContent, styles.noBorderTop])}>
          <AuctionButtonsContainer
            disableDraft
            disabled={
              !availableMarkets ||
              availableMarkets.length === 0 ||
              isLoading ||
              !selectedId
            }
            nextPage={() => updateAuctionWithMarketId()}
            loading={isLoading || loading}
          />
        </div>
      </div>
    </div>
  );
};

export default ChooseAnAccount;
