const isProduction = process.env.GATSBY_PUBLIC_ENV === "production";
const GAKey = process.env.GATSBY_PUBLIC_GOOGLE_ANALYTICS;
export const pageview = (url) => {
  if (
    typeof window !== "undefined" &&
    isProduction &&
    typeof (window as any).gtag !== "undefined"
  ) {
    (window as any).gtag("config", GAKey, {
      page_path: url,
    });
  }
};

export const event = ({ action, params }) => {
  if (
    typeof window !== "undefined" &&
    isProduction &&
    typeof (window as any).gtag !== "undefined"
  ) {
    (window as any).gtag("event", action, params);
  } else {
    console.log({ label: "event", action, params });
  }
};
