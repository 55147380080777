import React from "react";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";
import { AuthProvider } from "../../../../components/Auth/auth";
import DashboardLayout from "../../../../components/DashboardLayout";
import SimpleSpring from "../../../../components/Transitions/transitions";
import AuctionCreationNav from "../../../../components/PageComponents/Dashboard/Auctions/AuctionCreation/internal/AuctionCreationNav";
import ChooseAnAccount from "../../../../components/PageComponents/Dashboard/Auctions/AuctionCreation/steps/ChooseAnAccount";
import { getAuction } from "../../../../store/auctionModal/selectors";
import { Auction } from "../../../../types/Auction";
import { PathTypes } from "../../../../components/PageComponents/Dashboard/Auctions/NewAuctionCreation/Types/PathTypes";
import { MethodTypes } from "../../../../enums/MethodTypes";

const ChooseAccount = ({ location }) => {
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const fromPath =
    location.state && location.state.fromPath ? location.state.fromPath : null;

  const handleBack = () => {
    if (fromPath === PathTypes.AUCTION_CREATION_MAIN) {
      navigate("/dashboard/auctions/new/");
    } else if (fromPath === PathTypes.AUCTIONS) {
      navigate("/dashboard/auctions/");
    } else if (fromPath === PathTypes.DASHBOARD) {
      navigate("/dashboard/");
    }
  };

  return (
    <AuthProvider>
      <SimpleSpring location={location}>
        <DashboardLayout
          hideFooter
          style={{ paddingTop: 0 }}
          hideNav
          title="Bidddy | Choose Account"
        >
          <AuctionCreationNav
            onBack={() => handleBack()}
            step={auction.type === MethodTypes.code ? 2 : 5}
            totalSteps={auction.type === MethodTypes.code ? 4 : 8}
            title="Choose an Account"
          />
          <ChooseAnAccount location={location} />
        </DashboardLayout>
      </SimpleSpring>
    </AuthProvider>
  );
};

export default ChooseAccount;
